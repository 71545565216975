/* Styling for richtext component. Make button line horizontal and vertical in mobile.*/

.rt-buttonshorizontal {
    margin-bottom: pxToRem(32px);
    display:flex;
    flex-direction: column;
    justify-content:center;

    @media #{$tabletUp} {
      flex-direction: row;
      flex-wrap:wrap;
    }

    p{
      text-align: center;
    }

    .button {
      margin-left:pxToRem(5px);
      margin-right:pxToRem(5px);
      padding: 0;
  
      @media #{$tabletUp} {
        min-width: 190px; 
      }  
  
      @media #{$desktopLgUp} {
        min-width: 210px;
      } 
    }  
}
.section-text {

    .button a {
        padding: 0.5rem 2rem;
        display: inline-block;
        text-decoration: none; 
    }

   .button__primary a {
        color:white;   
  } 
}