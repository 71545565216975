@import "partials/contact-area";

.site-contact-bottom {
  margin-bottom: pxToRem(32px);

  @media #{$tabletUp} {
    margin-bottom: pxToRem(64px);
  }

  @media #{$desktopUp} {
    margin-bottom: pxToRem(64px);
  }

  @media #{$widescreenUp} {
    margin-bottom: pxToRem(64px);
  }
}

.site-contact-top {
  margin-top: pxToRem(32px);

  @media #{$tabletUp} {
    margin-top: pxToRem(64px);
  }

  @media #{$desktopUp} {
    margin-top: pxToRem(64px);
  }

  @media #{$widescreenUp} {
    margin-top: pxToRem(64px);
  }
}

/*
  BELOW IS NOT IN USE!!!
 */

/*
.site-page {
  &__content {
    margin: pxToRem(64px) 0;

    @media #{$tabletUp} {
      margin: pxToRem(32px) 0;
    }
  }

  &__description {
    margin: pxToRem(32px) pxToRem(16px);

    @media #{$tabletUp} {
      margin: pxToRem(64px) pxToRem(32px);
    }

    @media #{$desktopUp} {
      margin: pxToRem(64px) pxToRem(170.5px);
    }

    @media #{$widescreenUp} {
      margin: pxToRem(64px) pxToRem(190px);
    }
  }

  &__contacts {
    &-title {
      margin-bottom: pxToRem(64px);
    }
  }
}

*/
