@import './partials/car_contact';
@import './partials/contact_link';

.product-page {
  &__margin {
    margin-bottom: pxToRem(32px);

    @media #{$desktopLgUp} {
      margin: 0 pxToRem(32px) pxToRem(32px);
    }

    @media #{$widescreenUp} {
      margin: 0 pxToRem(64px) pxToRem(32px);
    }
  }

  &__section {
    &-title {
      font-size: pxToRem(18px);
      line-height: pxToRem(24px);
    }

    &-desc {
      .button {
        white-space: normal;
        font-size: 13px;
      }
    }

    &-overview {
      max-width: 620px;

      @media #{$desktopLgUp} {
        max-width: none;
      }
    }
  }

  &-location {
    font-size: pxToRem(12px);
    margin-bottom: pxToRem(9.5px);
    margin-top: pxToRem(8px);

    @media #{$desktopLgUp} {
      margin-top: pxToRem(15px);
      margin-bottom: pxToRem(14.6px);
    }

    @media #{$widescreenUp} {
      margin-top: pxToRem(24px);
      margin-bottom: pxToRem(22px);
    }

    svg {
      vertical-align: sub;
      margin-right: pxToRem(8px);
      margin-left: pxToRem(16px);
    }
  }

  &__meta {
    padding: 0 pxToRem(16px);

    @media #{$desktopLgUp} {
      padding: 0;
    }

    &-section {
      padding: 0 pxToRem(11px);
      font-size: pxToRem(14px);
      line-height: pxToRem(24px);

      .button__primary {
        line-height: pxToRem(22px);
      }
    }

    &-title {
      font-size: pxToRem(24px);
      line-height: pxToRem(28px);
      margin-top: pxToRem(32px);

      @media #{$desktopLgUp} {
        margin-top: 0;
      }
    }

    &-subtitle {
      font-size: pxToRem(14px);
      line-height: pxToRem(18px);
      font-weight: 300;
      font-family: $font-base;
      text-transform: initial;
      margin-bottom: pxToRem(14px);
    }

    &-price {
      color: $color-red-2;
      font-size: pxToRem(25px);
      line-height: pxToRem(29px);
      font-weight: bold;
    }

    &-choice {
      border: 1px solid #ececec;
      box-sizing: border-box;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      padding: pxToRem(8px) pxToRem(16px);

      &-title {
        font-size: pxToRem(14px);
        line-height: pxToRem(20px);
        margin-bottom: pxToRem(2px);
      }

      &-meta {
        font-size: pxToRem(12px);
        line-height: pxToRem(17px);
      }
    }
  }

  &__carousel {
    position: relative;

    .hedin-carousel__item {
      overflow-y: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        max-height: 512px;
      }
    }

    &-thumbs {
      width: 100%;
      position: relative;
      overflow: hidden;
      margin-top: pxToRem(8px);

      .swiper-slide-thumb-active img {
        border-bottom: 3px solid #000;
      }

      .carousel__thumbs {
        margin: 0;

        .swiper-wrapper {
          background: none;
          box-shadow: none;
        }

        img {
          max-height: 85px;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center;
          padding-bottom: pxToRem(3px);
        }
      }
    }
  }

  &__reserved {
    position: absolute;
    bottom: 10px;
    right: 0;
    width: 100%;
    background-color: $color-black;
    opacity: 0.6;
    z-index: 10;

    & span {
      color: $color-white;
      font-size: pxToRem(24px);
    }
  }

  &__sold {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: $color-black;
    opacity: 0.6;
    z-index: 10;
    max-height: 512px;

    & span {
      color: $color-white;
      font-size: pxToRem(24px);
    }
  }

  &__overview-item {
    width: auto;
    font-size: pxToRem(14px);
    line-height: pxToRem(18px);
    margin-right: pxToRem(8px);
    margin-bottom: pxToRem(28px);

    @media #{$desktopLgUp} {
      margin-right: pxToRem(32px);
    }

    svg {
      margin-right: pxToRem(8px);
      width: pxToRem(32px);
      height: auto;
      align-self: center;
    }
  }

  &__button {
    padding: pxToRem(8px) pxToRem(16px);
    margin-top: pxToRem(8px);

    &-icon {
      margin-top: pxToRem(8px);
      margin-left: pxToRem(34px);
    }

    &:hover {
      .product-page__button-icon {
        path {
          stroke: #fff !important;
        }
      }
    }
  }

  hr {
    border-color: $color-gray-light;
  }

  .sheet {
    font-size: pxToRem(16px);
    line-height: pxToRem(24px);
  }

  &-body__section {
    padding-top: pxToRem(32px);
    padding-bottom: pxToRem(32px);

    @media #{$desktopLgUp} {
      padding-top: pxToRem(48px);
      padding-bottom: pxToRem(48px);
      padding-left: pxToRem(32px) !important;
      padding-right: pxToRem(32px) !important;
      margin: 0 auto;
      max-width: 960px;
    }

    @media #{$widescreenUp} {
      max-width: 906px;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  &__tech-data {
    .col-md-4 {
      @media #{$tabletUp} {
        max-width: 306px;
      }

      @media #{$desktopLgUp} {
        max-width: 354px;
      }
    }
  }

  &__product-specifications {
    ul {
      width: 340px;
    }
  }
}

.car-card {
  &__image-container {
    border-bottom: 1px solid #ececec;
  }
}
