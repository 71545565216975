.car-contact {
  background: $color-white;
  flex-direction: column-reverse;
  display: flex;
  flex-wrap: wrap;

  @media #{$tabletUp} {
    flex-direction: row;
  }

  &-section {
    position: relative;
    z-index: 10;
    margin: pxToRem(-56px) pxToRem(16px) 0;

    @media #{$tabletUp} {
      margin: pxToRem(-96px) pxToRem(16px) 0;
    }

    @media #{$desktopUp} {
      margin: pxToRem(-64px) pxToRem(32px) 0;
    }

    @media #{$widescreenUp} {
      margin: pxToRem(-64px) pxToRem(48px) 0;
    }
  }

  &-wrapper {
    @media #{$tabletUp} {
      padding-bottom: pxToRem(72px);
    }

    @media #{$widescreenUp} {
      padding-bottom: pxToRem(128px);
    }
  }
}

.car-contact__left,
.car-contact__right {
  padding: pxToRem(16px);

  @media #{$mobileUp} {
    padding: pxToRem(24px);
  }

  @media #{$tabletUp} {
    padding: pxToRem(40px);
    flex-basis: 50%;
    width: 50%;
  }

  @media #{$desktopUp} {
    padding: pxToRem(56px);
  }
}

.car-contact__left {
  display: flex;
  flex-direction: column;
  flex-shrink: 1;
  border-top: 1px solid $color-gray-light-2;

  @media #{$tabletUp} {
    border-top: 0;
  }
}

.car-contact__right {
  flex-shrink: 0;
  border-left: 1px solid $color-gray-light-2;
  padding-bottom: pxToRem(32px);

  &-map {
    @media #{$tabletUp} {
      padding-bottom: 0;
    }
  }
}

.car-contact__sellers {
  padding-top: pxToRem(32px);
  border-top: 1px solid $color-gray-light-2;
  margin-top: pxToRem(32px);
}

.car-contact__subline {
  color: $color-gray;
  font-weight: 600;
  text-transform: uppercase;
  font-size: pxToRem(12px);
  line-height: pxToRem(18px);
  letter-spacing: 1px;
  margin-bottom: pxToRem(18px);

  @media #{$mobileUp} {
    font-size: pxToRem(14px);
    margin-bottom: pxToRem(24px);
    letter-spacing: 1.5px;
  }
}

.car-contact__title {
  text-transform: uppercase;
  font-size: pxToRem(18px);
  line-height: pxToRem(24px);
  letter-spacing: 1px;
  margin: 0 0 pxToRem(18px);

  @media #{$tabletUp} {
    font-size: pxToRem(24px);
    line-height: pxToRem(32px);
  }

  @media #{$desktopUp} {
    font-size: pxToRem(32px);
    line-height: pxToRem(40px);
    letter-spacing: 2px;
  }
}

.car-contact__subtitle {
  font-size: pxToRem(18px);
  line-height: pxToRem(24px);
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin-bottom: pxToRem(18px);

  @media #{$tabletUp} {
    font-size: pxToRem(24px);
    line-height: pxToRem(32px);
    margin-bottom: pxToRem(24px);
  }
}

.car-contact__content {
  & .contact-link {
    margin-bottom: 0;
    line-height: pxToRem(32px);
  }
}

.seller__title {
  text-transform: uppercase;
  margin: 0 0 pxToRem(12px);
  font-size: pxToRem(16px);
  line-height: pxToRem(24px);
}
