.hb-spinner {
  position: relative;
  height: 40px;
  width: 40px;
  border: 3px solid transparent;
  border-top-color: black;
  margin: 5px 0;
  border-radius: 50%;
  animation: hbspin 1s linear infinite;

  &:before,
  &:after {
    content: '';
    position: absolute;
    border: 3px solid transparent;
    border-radius: 50%;
  }

  &:before {
    border-top-color: black;
    top: -12px;
    left: -12px;
    right: -12px;
    bottom: -12px;
    animation: hbspin 3s linear infinite;
  }

  &-primary {
    border-top-color: $color-primary;

    &:before {
      border-top-color: $color-primary;
    }
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -.125em;
  border: .25em solid;
  border-right: .25em solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em
}


@media(prefers-reduced-motion: reduce) {
  .spinner-border {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s
  }
}

@keyframes hbspin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(1turn)
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn)
  }
}
