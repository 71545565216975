.articles {
  $gapSize: pxToRem(32px);

    & .section {
      padding: 0;
    }
    
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: $gapSize;

      > * {
        width: 100%;
      }

      @media #{$tabletUp} {
        & .card {
          width: 50%;
        }
      }

      @media #{$desktopLgUp} {
        & .card {
          flex: 1;
          min-width: calc((100% / 3) - #{$gapSize});
          max-width: calc(50% - #{$gapSize});
        }
      }

      & .hero {
         width: 100%;
      }

      & .blurb__inner {
        width: 100%;
        padding: 0;
      }
    }
}