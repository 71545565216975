// form-row is removed in Bootstrap 5, but we add support for it here. As other sites
.form-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));

  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
}
.hb-form {
  input::placeholder {
    color:$color-primary;
  }
  textarea::placeholder {
    color:$color-primary;
  }
  h1 {
    line-height:40px;
  }  
  p {
    line-height:28px;
  }
  .form-group {
    input, textarea, select, option {
      background-color: $input-background-color !important; 
      border-color:$color-primary;
      border-left: none;
      border-right: none;
      border-top: none;
      border-width: 2px;
    }
    label {
      color:$color-primary; 
    }
    input[type=checkbox] {
      height:21px;
      width:21px;
    }  
  }
  .form-checkbox {
    label{
      text-transform: none;
    }
  }  
}