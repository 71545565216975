// Checkbox
$checkbox-size-sm: 16px;
$checkbox-size-lg: 24px;

.checkbox {
  display: flex;
  flex-flow: row;
  align-items: center;

  &--disabled {
    opacity: 0.6;

    .checkbox__box {
      cursor: not-allowed;
    }
  }

  &--large {
    .checkbox__box {
      width: $checkbox-size-lg !important;
      height: $checkbox-size-lg !important;
      min-width: $checkbox-size-lg !important;
      min-height: $checkbox-size-lg !important;

      &--checked {
        border-width: 5px;
      }
    }
  }

  &--small {
    .checkbox__box {
      width: $checkbox-size-sm !important;
      height: $checkbox-size-sm !important;
      min-width: $checkbox-size-sm !important;
      min-height: $checkbox-size-sm !important;
    }
  }

  &__box {
    padding: 0 !important;
    margin-right: pxToRem(10px);
    border-radius: 0 !important;

    &--checked {
      border: 3px solid green;
      background-color: green;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  &__label {
    margin: 0;
  }
}
