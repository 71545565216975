// vendors
@import './sitecore.scss';

$gutters: (
  xs: 16px,
  sm: 24px,
  md: 32px,
  lg: 48px,
  xl: 64px,
);
// fix for .form-row
@import './vendors/bootstrap-mix';

// We've only imported variables, functions and mixins here because of imports of image.
// In Layout.js we've have imported everything, including the below. But as that is only SCSS and no CSS it won't mess up the CSS.
// If we were to import everything here, then all background-images would be broken in hedin-ui and throw errors.

@import './vendors/hedin-ui';
// base
@import './helpers/forms';

@import './components/fullscreen_carousel';
@import './components/_richtext';

// Pages?
@import './pages/product-page';
@import './pages/site-page';
@import './pages/articles';

//modules
@import './modules/checkbox.scss';
@import './modules/spinner.scss';

@import '~@hedinbil/hedin-ui/src/utils/styles/index';
@import 'fixes';
@import 'variables';

@import '~@hedinbil/hedin-ui/src/base/modules/CarModelCardList/car-model-car-card-list';

@import '~@hedinbil/hedin-ui/src/base/modules/breadcrumbs/breadcrumbs';
//layout

.page-wrapper {
  margin: auto;
  max-width: 1504px;
  width: 100%;
  background: #f8f8f8;
  overflow-x: hidden;
  position: relative;
}

.card {
  font-family: var(--font-display, sans-serif);
}

// Used for modals and filters.
@media #{$xlWidescreenUp} {
  .page-container {
    max-width: 1504px;
    margin: 0 auto;
    width: 100%;
    position: relative;
    pointer-events: none;

    & .hb-filter {
      pointer-events: auto;
    }
  }
}
body {
  background: #E5E5E5;
}