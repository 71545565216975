// Required
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$grid-breakpoints: (
        sm: 320px,
        md: 768px,
        lg: 1024px,
        xl: 1200px,
        xxl: 1504px
);

//$container-max-widths: (
//        sm: 540px,
//        md: 720px,
//        lg: 960px,
//        xl: 1200px,
//);

// Optional
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";

.row {
  --bs-gutter-x: 2rem;
}
