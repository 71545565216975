$color-primary: #006eb4;
$link-color: #006eb4;
$alert-border-color: #e0482e;
$alert-text-color: #000;
$special-price: #006eb4;

$navigation-bg-color: #fff;;
$navigation-color: #006eb4;
$navigation-sub-bg-color: #006eb4;
$navigation-sub-color: #fff;
$navigation-sub-border-color: #fff;
$navigation-hamburger-bg-color: #006eb4;
$navigation-sub-menu-bg-color :#006eb4;
$navigation-sub-menu-color:#fff;

$button-primary-bg-color: #006eb4;
$button-primary-border-color: #006eb4;
$button-primary-hover-bg-color: #004c7c;
$button-primary-color: #fff;
$button-primary-hover-border-color: #004c7c;

$footer-bg-color: #fff;
$footer-sub-bg-color: #fff;
$footer-link-color: #006eb4;
$footer-text-color: #666666;

$focus-input-border-color: #006eb4;
$focus-input-outline-color: rgba(0, 110, 180, 0.3);
$input-primary-color: #006eb4;
$input-range-outline-color:rgba(0, 110, 180, 0.3);
$input-background-color: #f3f3f3;

$progress-bar-bg:#B5B5B5;
$progress-bar-primary-bg: #006eb4;

$heading-color: #006eb4;
$text-color: #000;

$card-title-color: #000;

@import "~@hedinbil/hedin-ui/src/base/variables/colors";
@import "~@hedinbil/hedin-ui/src/base/variables/typography";
@import "~@hedinbil/hedin-ui/src/base/variables/breakpoints";
@import "~@hedinbil/hedin-ui/src/base/variables/animations";
@import "~@hedinbil/hedin-ui/src/base/functions";
@import "~@hedinbil/hedin-ui/src/base/mixins";
@import "~@hedinbil/hedin-ui/src/base/root";
@import "~@hedinbil/hedin-ui/src/shop/root";
@import "~@hedinbil/hedin-ui/src/editor/root";