.fullscreen-carousel {
  width: 100%;

  &__carousel {
    position: relative;

    .swiper-button-prev, .swiper-button-next {
      opacity: 1;
    }

    .fullscreen-carousel__btn {
      @media #{$desktopUp} {
        right: 48px;
      }
    }

    .hedin-carousel__item {
      height: 86vh;
      overflow: hidden;

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }
  }

  &__thumbs {
    @media #{$desktopUp} {
      margin-top: pxToRem(32px);
    }

    .swiper-wrapper {
      background-color: transparent;
    }

    .hedin-carousel__item {
      flex-basis: auto;

      &.swiper-slide-thumb-active img {
        border-bottom: 3px solid $color-green;
      }

      img {
        width: 138px;
        object-fit: cover;
        object-position: center;
        height: 100px;
      }
    }
  }

  &__btn {
    position: absolute;
    bottom: 22px;
    z-index: 10000;
    right: 7px;
    padding: pxToRem(5px);
    height: 42px;

    &:hover {
      transform: scale(1.15);
    }

    svg {
      height: 32px;
      width: 32px;
      -webkit-filter: drop-shadow(0 2px 2px #1a1a1a);
      filter: drop-shadow(0 2px 2px #1a1a1a);
    }
  }
}
