.contact-area {
  border-bottom: 1px solid black;
  margin: 0 pxToRem(16px) 0;

  @media #{$tabletUp} {
    margin: 0 pxToRem(16px) 0;
  }

  @media #{$desktopUp} {
    margin: 0 pxToRem(32px) 0;
  }

  @media #{$widescreenUp} {
    margin: 0 pxToRem(48px) 0;
  }

  &__container {
    @media #{$desktopUp} {
      padding-bottom: pxToRem(32px);
      padding-left: pxToRem(64px);
    }

    &-logos {
      flex-wrap: wrap;
      margin: pxToRem(32px) 0;

      @media #{$desktopUp} {
        margin: pxToRem(16px) 0 0 0;
      }

      & img {
        max-height: 52px;
        max-width: 52px;
        margin: 0 pxToRem(10px) pxToRem(20px) pxToRem(10px);

        @media #{$desktopUp} {
          margin: 0 pxToRem(20px) 0 0;
        }
      }
    }

  }

  &__content {
    flex-direction: column; 
    margin-top: pxToRem(-5px);
    line-height: pxToRem(24px);
    font-size: pxToRem(16px);

    @media #{$tabletUp} {
      padding-right: pxToRem(70px);
    }
    
    &-text {
      max-width: 100%;

      @media #{$tabletUp} {
        max-width: 260px;
      }      
    }
    
    & .button {
      width: 100%; 
      
      @media #{$tabletUp} {
        width:auto;
      }               
    }
  }

  &__open {
    &-days {
      flex-direction: column;
      padding-right: pxToRem(20px);
      margin-top: pxToRem(-5px);
      margin-bottom: pxToRem(10px);
           
      @media #{$tabletUp} {
        padding-right: pxToRem(70px);
      }
    }
    &-houers {
      flex-direction: column;
      margin-top: pxToRem(-5px);
      margin-bottom: pxToRem(10px);
    }
  }

  &__staff {
    &-container {
      @media #{$desktopUp} {
        padding-left: pxToRem(64px);
        padding-bottom: pxToRem(32px);
      }
    }
  }
}
