.contact-link {
  text-decoration: none;
  font-weight: 600;
  color: #12bd59;
  margin-bottom: pxToRem(14px);
  font-size: pxToRem(16px);
  line-height: pxToRem(24px);
  word-break: break-word;
  align-items: center;
  flex-wrap: nowrap;

  &-alt {
    color: #666666;
    font-weight: 400;
    font-size: pxToRem(12px);
    line-height: pxToRem(16px);
    letter-spacing: 1px;
  }

  &:hover {
    text-decoration: underline;
  }

  &:not(.contact-link__no-fill) {
    svg path {
      fill: #616161 !important;
    }
  }

  svg {
    margin-right: pxToRem(10px);
    margin-left: pxToRem(4px);
    height: 20px;
    min-width: 24px;
    vertical-align: text-bottom;
  }
}
