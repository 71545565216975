.button {
  text-transform: none;
}

.button__primary.button--icon:after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTYgMEw0LjkzOSAxLjA2MSA5LjEyNyA1LjI1SDB2MS41aDkuMTI3TDQuOTQgMTAuOTM5IDYgMTJsNi02eiIgZmlsbD0iIzE4MTgxOCIgZmlsbC1ydWxlPSJub256ZXJvIi8+PC9zdmc+') !important;
}

.h-link-arrow:after {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMjZweCIgaGVpZ2h0PSIyNnB4IiB2aWV3Qm94PSIwIDAgMjYgMjYiIHZlcnNpb249IjEuMSI+IDxkZWZzLz4gPGcgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+IDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0xMDU0LjAwMDAwMCwgLTE0MDMuMDAwMDAwKSI+IDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAuMDAwMDAwLCA4NjQuMDAwMDAwKSI+IDxnIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY0LjAwMDAwMCwgMTkxLjAwMDAwMCkiPiA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSg4NDAuMDAwMDAwLCA5Ny4wMDAwMDApIj4gPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNTcuMDAwMDAwLCA5Mi4wMDAwMDApIj4gPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMC4wMDAwMDAsIDE2MC4wMDAwMDApIj4gPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOTQuMDAwMDAwLCAwLjAwMDAwMCkiPiA8Zz4gPHBvbHlnb24gZmlsbD0iIzAwNmViNCIgZmlsbC1ydWxlPSJub256ZXJvIiBwb2ludHM9IjEyIDYgMTAuOTM4NzUgNy4wNjEyNSAxNS4xMjc1IDExLjI1IDYgMTEuMjUgNiAxMi43NSAxNS4xMjc1IDEyLjc1IDEwLjkzODc1IDE2LjkzODc1IDEyIDE4IDE4IDEyIi8+IDxjaXJjbGUgc3Ryb2tlPSIjRTBFMEUwIiBjeD0iMTIiIGN5PSIxMiIgcj0iMTIiLz4gPC9nPiA8L2c+IDwvZz4gPC9nPiA8L2c+IDwvZz4gPC9nPiA8L2c+IDwvZz4gPC9zdmc+');
}

a {
  text-decoration: underline;
}

.section-title {
  text-transform: none !important;
}

.header__logo img {
  width: 100px;
  height: 40px;
  @media #{$desktopUp} {
    width: 123px;
    height: 84px;
  }
}

.header__extra {
  justify-content: flex-start;
  margin-left: 0.6rem;
}

.header__extra-icon {
  height: 1.9rem !important;
  @media #{$desktopUp} {
    display: none;
  }
}

.navigation__scroller {
  height: 100%;
}

.navigation__items {
  height: 100%;
  display: flex;
  align-items: stretch;
}
.navigation__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation__link {
  padding-top: 0;
  padding-bottom: 0;
}

@media #{$tabletUp} {
  .sub-navigation .sub-navigation .sub-navigation__link {
    background-color: #004c7c;
  }
}

.sub-navigation .sub-navigation .sub-navigation .sub-navigation__link {
  background-color: #004c7c;
}

.navigation,
.link-carousel__header,
.link-carousel__title,
.hero__title,
.carousel__thumb-title,
.card__title {
  text-transform: none;
}

.stage-row__title {
  font-size: 20px;
  letter-spacing: normal !important;
}

.carousel {
  background: none !important;
}

.blurb {
  background: none;
}

.sheet {
  background: none !important;
}

.card {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.price-detail .main-value__red {
  color: $special-price;
}

.product-page__carousel-thumbs .swiper-slide-thumb-active img {
  border-bottom: 3px solid $color-primary;
}

.swiper-button-prev-white svg path,
.swiper-button-next-white svg path {
  stroke: #fff;
}
.button--icon {
  &:after,
  &:before {
    filter: brightness(100);
  }
}
.swiper-button-prev,
.swiper-button-next {
  background-color: $color-primary;

  & svg path {
    stroke: #fff;
  }
}

.sub-navigation__toggle-button {
  &[aria-expanded='true'],
  .expanded {
    background-image: url(../icons/minus-icon-white.svg);

    @media #{$mobileUp} {
      background-image: url(../icons/minus-icon-white.svg);
    }
  }
}

.navigation__item .header-button {
  min-width: 0;
}
.button {
  text-transform: uppercase;
}

.contact-link {
  color: #006eb4;
}

.contact-area__staff {
  .card {
    box-shadow: none !important;
  }
}

.row-container {
  @media only screen and (max-width: 1504px) {
    margin-top: 24px !important;
  }
}

.hb-form {
  .form-group {
    label {
      input[type='checkbox'] {
        vertical-align: sub;
      }
    }
  }
}
